<template>
    <div id="app">
        <HomePage></HomePage>
        <LoginPage></LoginPage>
    </div>
</template>

<style>
* {
    padding: 0;
    margin: 0;
}

html body {
    overflow: hidden;
}

#app {
    width: 100vw;
    height: 100vh;
    background-color: #4d5a6b;
    background-image: url("https://faixin.cn/images/makima.png");
    background-repeat: no-repeat;
    background-size: cover;
}
</style>

<script>
import HomePage from "./view/HomePage.vue"
import LoginPage from "./view/LoginPage.vue"
export default {
    name: "App",
    components: {
        HomePage,
        LoginPage,
    },
    data() {
        return {

        }
    },
    mounted() {
        this.jumpToWhere();
    },
}

</script>