<template>
    <div id="userlist">
        <!-- <UserCard :cardName="'yiha'"></UserCard> -->
        <!-- v-for="(chatMsg, index) in this.GLOBAL.chatMsgs" :key="index" :avatar="chatMsg.avatar" -->
        <UserCard v-for="(user,index) in userlist" :key="index" :cardName="user.name" :avatar="user.avatar"/>
    </div>
</template>
  
<style>
#userlist {
    text-align: center;
    padding: 10px;
    padding-top: 20px;
    padding-right: 0;
    background-color: #282a36;
    height: 100%;
    position: relative;
    overflow-y: auto;
}


#userlist::-webkit-scrollbar {
    width: 0;
}
</style>
  
<script>
import UserCard from "../components/UserCard.vue"
import { EventBus } from "../api/eventBus";

export default {
    name: "UserList",
    components: {
        UserCard,
    },
    created() {
        EventBus.$on('refreshUserList', () => {
            this.userlist = this.GLOBAL.userlist
            this.$forceUpdate();
        });
    },
    data() {
        return {
            selectedUser: null,
            userlist:{},
        };
    },
    methods: {
        selectUser(userName) {
            this.selectedUser = userName;
        },

    },
    mounted() {

    },
};
</script>
  