<template>
    <div ref="userCard" class="userCard" :style="cardStyle" @click="handleClick">
        <img class="cardAvatar" :src=avatar alt="">
        <div class="cardName">{{ cardName }}</div>
    </div>
</template>

<style>
.cardName {
    color: white;
}

.cardAvatar {
    margin: 10px;
    width: 60px;
    height: 60px;
    border-radius: 13px;
}

.userCard {
    position: relative;
    margin: 0 auto;
    margin-bottom: 15px;
    width: 100%;
    height: 100px !important;
    background-color: #333643;
    border-radius: 10px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
}

.userCard:hover {
    background-color: rgb(103, 103, 103);
}

.userCardSelected {
    background-color: rgb(103, 103, 103);
}
</style>

<script>
import { EventBus } from "../api/eventBus";

export default {
    name: "UserCard",
    props: {
        cardName: {
            type: String,
            required: false,
        },
        avatar:{
            type: String,
        }
    },
    computed: {
        cardStyle() {
            return {
                backgroundColor: this.cardName === this.$parent.selectedUser ? 'rgb(103, 103, 103)' : '#333643',
            };
        },
    },
    data() {
        return {
        }
    },
    methods: {
        handleClick(){
            if (this.cardName == "Public") {
                this.GLOBAL.sendMsg.receiver = ""
            }else{
                this.GLOBAL.sendMsg.receiver = this.cardName
            }
            console.log("===receiver===\n"+this.GLOBAL.sendMsg.receiver)
            this.$parent.selectUser(this.cardName);
            // 修改样式变量，触发样式更新
            EventBus.$emit('clickUserCard');
        },
    },
    mounted(){

    }
}
</script>
