<template>
    <div id="nav">
        <el-avatar :src="avatarURL" :shape="'square'" :size="55"></el-avatar>
        <i class="el-icon-s-comment" @click="changeName"></i>
        <i class="el-icon-user-solid" @click="foldUserList"></i>
        <i class="el-icon-picture" @click="uploadImg"></i>
        <i class="el-icon-video-camera" @click="videoChat"></i>
        <i class="el-icon-delete-solid" @click="clearMsg"></i>
        <input id="uploadImg" ref="uploadImg" type="file" @change="handleFileUpload" accept="image/*">
    </div>
</template>

<style>
#uploadImg {
    display: none;
}

#nav {
    color: rgb(129, 132, 134);
    font-size: 30px;
    text-align: center;
    padding: 10px;
    /* padding-bottom: 200px; */
    /* background-color: #333; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    backdrop-filter: blur(10px);
    /* 添加磨砂效果，可根据需要调整模糊程度 */

}

#nav .el-avatar {
    margin: 0 auto;
    /* 水平居中 */
}

#nav i {
    margin: 0 auto;
    transition: color 0.3s;
    margin-top: 30px;
}

#nav i:hover {
    color: white;
    margin: 0 auto;
    transition: color 0.3s;
    margin-top: 30px;
}
</style>

<script>
import { ImageCompressor } from 'image-compressor';
export default {
    name: "NavigatorBar",
    data() {
        return {
            avatarURL: "https://faixin.cn/images/SoP.ico",
            userListFlag: false,
        };
    },
    props: {
        drawervideo: Boolean,
    },
    methods: {
        uploadImg() {
            this.$refs.uploadImg.click()
        },
        async handleFileUpload(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const file = input.files[0];
                // 检查文件类型是否为图像
                if (file.type.startsWith('image/')) {
                    const reader = new FileReader();
                    // 读取文件内容
                    reader.readAsDataURL(file);

                    reader.onload = async () => {
                        // 创建一个图片元素
                        const image = new Image();

                        image.src = reader.result;

                        image.onload = async () => {
                            // 压缩图片
                            // const compressedBase64 = await this.compressImage(image);
                            // this.addNewMessage(this.GLOBAL.userName,"", compressedBase64, true, this.GLOBAL.avatar)
                            // this.sendToServer("message", "img", compressedBase64, this.GLOBAL.avatar)

                            //不压缩图片
                            this.addNewMessage(this.GLOBAL.userName, "", image.src, true, this.GLOBAL.avatar)
                            this.sendToServer("message", "img", image.src, this.GLOBAL.avatar)
                        };


                    };


                } else {
                    alert('请选择一个图像文件');
                }
            }
            this.$refs.uploadImg.value = ""
        },

        async compressImageBackUp(image, maxSizeInBytes) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // 设置画布大小
            canvas.width = image.width; // 设置压缩后的宽度
            canvas.height = image.height; // 设置压缩后的高度

            let quality = 0.9; // 初始的图像质量
            for (let i = 0; i < 100; i++) {

                // 在画布上绘制图像并进行压缩
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

                // 将画布内容转为Base64编码
                const compressedBase64 = canvas.toDataURL('image/jpeg', quality);

                // 将Base64编码转为Blob对象
                const blob = await fetch(compressedBase64).then((res) => res.blob());

                // 检查Blob的大小是否小于目标大小
                if (blob.size <= maxSizeInBytes || i == 99) {
                    return compressedBase64;
                }

                // 如果图像大小仍然过大，降低质量
                quality -= 0.01;
                console.log("压缩", i + 1, "次")

            }

        },

        async compressImage(image) {
            const imageCompressor = new ImageCompressor;
            const compressorSettings = {
                toWidth: 100,
                toHeight: 100,
                mimeType: 'image/png',
                mode: 'strict',
                quality: 0.6,
                grayScale: true,
                sepia: true,
                threshold: 127,
                vReverse: true,
                hReverse: true,
                speed: 'low'
            }

            console.log(imageCompressor.run(image, compressorSettings))


        },
        // 辅助函数：将 Blob 转换为 Base64
        blobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
                reader.readAsDataURL(blob);
            });
        },
        foldUserList() {

        },

        clearMsg() {
            var chatBox = document.getElementById("chatBoard")
            while (chatBox.firstChild) {
                chatBox.removeChild(chatBox.firstChild)
            }
        },
        changeName() {
            this.GLOBAL.userName = prompt()
            alert(this.GLOBAL.userName)
            localStorage.setItem("userName", this.GLOBAL.userName)
        },
        videoChat() {
            this.$emit('triggerMethod')
        }


    },

    mounted() {

    },



};
</script>