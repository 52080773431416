<template>
  <div id="home">
    <div id="nav">
      <Navigator @triggerMethod="callVideoChat" ></Navigator>
    </div>
    <div id="userlist">
      <UserList></UserList>
    </div>
    <div id="chatbox">
      <ChatBox ref="chatBox"></ChatBox>
    </div>
   
  </div>
</template>
  
<style>
#home {
  width: 90vw;
  height: 90vh;
  /* background-color: rgb(39, 42, 55); */
  border-radius: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  overflow: hidden;

  backdrop-filter: saturate(180%) blur(5px) !important;
  -webkit-backdrop-filter: saturate(180%) blur(5px) !important;
  background: rgba(228, 237, 250, .1) !important;
}

#nav {
  flex: 0.5;
  background-color: #323840;
}

#userlist {
  flex: 2.5;
  background-color: #282a36;
}

#chatbox {
  flex: 8;
  background-color: #282a36;
  position: relative;
}
</style>

<script>
import Navigator from "../components/Navigator.vue"
import UserList from "../components/UserList.vue"
import ChatBox from "../components/ChatBox.vue"

export default {
  name: "HomePage",
  components: {
    Navigator,
    UserList,
    ChatBox,
  },
  methods:{
    callVideoChat(){
      this.$refs.chatBox.videoChat()
    },
  }

}
</script>
  