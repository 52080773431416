<template>
    <div id="message" :class="['message', isSent ? 'reverseMessage' : 'rowMessage']">

        <div id="avatarBox" @mouseover="showName" @mouseleave="hideName">
            <img ref="avatar" :src=avatar class="user-avatar" alt="" @click="zoomImg(avatar)">
            <div id = "username" >{{ name }}</div>
        </div>

        <!-- 使用 v-if 条件指令，只在 img 存在时显示 -->
        <img v-if="img" :src="img" :class="['image-bubble', isSent ? 'sent' : 'received']" alt="Image Message" @click="zoomImg(img)">

        <!-- 使用 v-else，只在 img 不存在时显示 -->
        <div v-else :class="['message-bubble', isSent ? 'sent' : 'received']">
            <div id="fil"> </div>
            {{ msg }}
        </div>
       
    </div>
</template>

<style>
#fil{
    height: 6.5px;
}

#username{
    margin: 0;
}
.message {
    display: flex;
    color: #fff;
}

.rowMessage {
    flex-direction: row;
}

.reverseMessage {
    flex-direction: row-reverse;
}



#avatarBox {
    color: white;
    text-align: center;
    height: 100%;
    width: 60px;
}

.message-bubble {
    background-color: #4d5a6b;
    color: #fff;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
    max-width: 70%;
    /* min-width: 30px; */
    
    word-wrap: break-word;
    clear: both;

}

.sent {
    background-color: #0073e6;
}

.received {
    background-color: #4d5a6b;
}

.image-bubble {
    padding: 10px;
    border-radius: 15px;
    margin: 10px;
    max-width: 40%;
    clear: both;
    display: block;
    overflow: hidden;
}



.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 10px;
    margin-bottom: 0px;
    object-fit: cover;
    position: relative;
    transition: transform 0.3s ease-in-out;
}

.user-avatar:hover {
    transform: scale(1.5);

}
</style>

<script>
export default {
    name: "MessageBubble",
    props: {
        msg: {
            type: String
        },
        isSent: {
            type: Boolean
        },
        name: {
            type: String
        },
        img: {
            type: String
        },
        avatar: {
            type: String
        }
    },
    data() {
        return {
            tempMsg: "",
        }
    },
    methods: {
        showName() {

        },
        hideName() {

        },
        zoomImg(src) {
            // 创建一个蒙版元素
            var overlay = document.createElement("div");
            overlay.style.position = "fixed";
            overlay.style.top = "0";
            overlay.style.left = "0";
            overlay.style.width = "100%";
            overlay.style.height = "100%";
            overlay.style.backgroundColor = "rgba(0, 0, 0, 0)";
            overlay.style.display = "flex";
            overlay.style.alignItems = "center";
            overlay.style.justifyContent = "center";
            overlay.style.zIndex = "1000";
            overlay.style.transition = "background-color 0.3s ease-in-out";

            // 创建一个图片元素
            var img = document.createElement("img");
            img.src = src;
            img.style.animation = "fadeIn 0.5s ease"; // 添加渐变动画
            if (img.naturalWidth > img.naturalHeight) {
                img.style.width = "80vw";
            } else {
                img.style.height = "80vh";
            }

            // 添加图片到蒙版中
            overlay.appendChild(img);

            // 点击蒙版时隐藏图片和蒙版
            overlay.addEventListener("click", function () {
                overlay.style.backgroundColor = "rgba(0, 0, 0, 0)";
                img.style.opacity = 0; // 隐藏图片
                setTimeout(function () {
                    overlay.style.display = "none";
                }, 300); // 等待过渡效果完成后隐藏蒙版
            });

            // 将蒙版添加到页面的body中
            document.body.appendChild(overlay);

            // 触发过渡效果
            setTimeout(function () {
                overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
                img.style.opacity = 1; // 显示图片
            }, 0);
        },
    }

}
</script>