<template>
    <div id="loginPage">
        <el-dialog class="bg" id="login" title="登录/注册" :visible.sync="loginFlag" :modal="true">
            <el-form>
                <el-form-item label="">
                    <el-input v-model="userName" autocomplete="off" placeholder="用户名">
                    </el-input>
                    <el-input v-model="pwd" autocomplete="off" placeholder="密码">
                    </el-input>
                </el-form-item>
            </el-form>

            <div ref="logInfo"></div>

            <div slot="footer" class="dialog-footer" style="text-align: center !important; ">
                <el-button style="width: 30%;" type="primary" @click="handleLogIn">登录</el-button>
                <el-button style="width: 30%;" type="primary" @click="handleSignUp">注册</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<style>
.bg {
    background-color: #fff;
    background-image: url("https://faixin.cn/images/makima.png");
    background-repeat: no-repeat;
    background-size: cover;
}
</style>

<script>
export default {
    name: "LoginPage",
    data() {
        return {
            userName: "",
            pwd: "",
            loginFlag: false,

        }
    },
    methods: {
        handleLogIn() {
            localStorage.setItem("userName", this.userName)
            this.login(this.userName,this.pwd)
            this.sendToServer("userNum",null,null,"https://faixin.cn/images/SoP.ico")
            this.loginFlag = false
            
        },
        handleSignUp() {
            this.loginFlag = false
        },
    },
    mounted() {
        var name = localStorage.getItem("userName")
        if (name == null) {
            this.loginFlag = true
        }
    },
}
</script>