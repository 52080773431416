import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/reset.css";
import global_func from './api/global_func';

Vue.prototype.GLOBAL = global_func

Vue.use(ElementUI);
Vue.use(global_func);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
