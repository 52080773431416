<template>
    <div id="chatArea">
        <div id="chatBoard" ref="chatBoard">
            <MessageBubble v-for="(chatMsg, index) in this.GLOBAL.chatMsgs" :key="index" :avatar="chatMsg.avatar"
                :msg="chatMsg.msg" :isSent="chatMsg.isSent" :img="chatMsg.img" :name="chatMsg.name">
            </MessageBubble>
        </div>
        <div id="chatInput">
            <input id="inputs" class="inputs" @keydown="handleSend($event)" v-model="message" />
        </div>

        <div id="videoChat">
            <el-drawer v-if="true" class="videoBox" ref="dv" :withHeader="false" :visible.sync="drawervideo" :direction="'btt'" :size="'100%'" :before-close="stopCamera" :modal="false">

                <button class="videoBtn" type="button" id="signalBtn" onclick="initVar()">
                    点击连接到信令服务器
                </button>

                <button class="videoBtn" type="button" id="pushBtn" onclick="start()">
                    推流
                </button>

                <video id="local-video"></video>

                <div id="remotes">
                    <button class="videoBtn" type="button" id="enable-audio-button" onclick="enableAudio()">
                        启用音频
                    </button>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<style>
::v-deep .el-drawer{
    background-color: #166c88 !important
}
.videoBox {
    display: flex;
    text-align: center;
}
#remotes video {
    width: 100vw;
}

#local-video {
    display: none;
    width: 100%;

}

.videoBtn {
    width: 100%;

    background-color: #ADD8E6;
    /* 设置文字颜色为白色，以确保可读性 */
    color: #fff;
    /* 设置边框为无边框 */
    border: solid 1px white;
    /* 添加一些内边距，使按钮内容不会紧贴边框 */
    padding: 10px 20px;
    /* 设置鼠标悬停时的背景颜色，可以根据需要自定义 */
    transition: background-color 0.3s ease;
    /* 添加光标指针样式，以表示按钮是可点击的 */
    cursor: pointer;
}

#chatArea {
    border-radius: 15px;
    display: flex;
    overflow: hidden;
    width: 95%;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    background-color: #282a36;
}

#chatBoard {
    flex: 8.5;
    background-color: rgb(138, 138, 138, 0.1);
    overflow: auto;
    padding-left: 4%;
    padding-right: 4%;
}

#chatBoard::-webkit-scrollbar {
    width: 0;
}

#chatInput {
    overflow: hidden;
    flex: 1.5;
    background-color: rgb(138, 138, 138, 0.1);
}

.inputs {
    width: 90%;
    height: 50px;
    background-color: rgb(66, 70, 86);
    /* border-radius: 15px; */
    border: 2px solid rgb(34, 135, 225, 0.3);
    padding: 10px;
    box-sizing: border-box;
    transition: 0.2s;
    font-size: 20px;
    color: #fff;
    margin: 0 5%;
    outline: none;


}

.videoBox {
    display: flex;
    text-align: center;
}
</style>

<script>
import { EventBus } from "../api/eventBus";
import MessageBubble from './MessageBubble.vue';

export default {
    name: "ChatBox",
    data() {
        return {
            message: "",
            drawervideo: false,
        };
    },
    components: {
        MessageBubble
    },
    created() {
        EventBus.$on('refreshBComponent', () => {
            this.$forceUpdate();
        });
        EventBus.$on('clickUserCard', () => {
            this.$forceUpdate();
        });
    },
    mounted() {

    },
    methods: {
        handleSend(event) {
            if (event.keyCode == 13) {
                if (this.message != "") {
                    var ava = this.GLOBAL.avatar
                    this.addNewMessage(this.GLOBAL.userName, this.message, "", true, ava)
                    this.sendToServer("message", "text", this.message, ava)
                    this.message = ""
                }
            }
        },
        refresh() {

        },
        videoChat() {
            this.drawervideo = !this.drawervideo;
            console.log("videoChat")
        },
        stopCamera(done) {
            done()
        },


    },
}
</script>