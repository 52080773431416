import { EventBus } from "../api/eventBus";
import Vue from 'vue'
import CryptoJS from 'crypto-js';

var userName = localStorage.getItem("userName")
if (userName == null) {
    userName = "default"
}

var msgPool = {}

var chatMsgs = {
    chatMsg: {
        avatar: "https://faixin.cn/images/SoP.ico",
        name: "SoP",
        msg: "",
        isSent: true,
        img: "https://faixin.cn/images/Goimg/display.png"
    },
    chatMsg2: {
        avatar: "https://faixin.cn/images/Goimg/fulilian.jpg",
        name: "芙莉莲",
        msg: "伊哈！",
        isSent: false,
        img: "",

    },
    chatMsg3: {
        avatar: "https://n.sinaimg.cn/sinakd20114/534/w712h622/20201126/e9ae-kentcvx5398612.jpg",
        name: "芙莉莲",
        msg: "",
        isSent: false,
        img: "https://faixin.cn/images/Goimg/duoduo.jpg",

    },
}

var userlist = {
    user1: {
        name: "Public",
        avatar: "https://faixin.cn/images/SoP.ico",
    }
}

var tempMsgs = {

}

var privateMsgs = {

}

var userNames = ['user1', 'user2', 'user3', 'user4']

var sendMsg = {
    receiver: receiver
}

// var AESkey = "yiha"

// var keyPair

var receiver = ""

var avatar = "https://faixin.cn/images/SoP.ico"

var botAvatar = "https://i.52112.com/icon/jpg/256/20200508/77366/3221474.jpg"

var sessionKey = "7777777777777777"

//判断用户是移动端还是PC端
function jumpToWhere() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        console.log("手机")
        window.location.href = 'https://chat.faixin.cn';
    } else {
        console.log("电脑")
    }
}

//addNewMessage是一个全局函数
function addNewMessage(name, msg, img, isSent, avatar) {
    const chatBox = document.getElementById("chatBoard")
    const key = 'chatMsg' + Object.keys(chatMsgs).length + 1;
    var newChatMsg = {
        name: name,
        msg: msg,
        isSent: isSent,
        img: img,
        avatar: avatar,
    };

    Vue.set(chatMsgs, key, newChatMsg);

    EventBus.$emit('refreshBComponent');
    smoothScrollToBottom(chatBox)
}

function clearUserList() {
    for (const key in userlist) {
        delete userlist[key];
    }
}

function addNewUser(name, avatar) {
    // 遍历userlist中的所有用户
    for (const key in userlist) {
        const user = userlist[key];

        // 如果找到一个与新用户具有相同名称和头像的用户，则返回
        if (user.name === name && user.avatar === avatar) {
            return;
        }
    }

    // 为新用户生成一个唯一的键
    const key = 'user' + Object.keys(userlist).length + 1;

    // 创建新用户对象并将其添加到userlist中
    var user = {
        name: name,
        avatar: avatar,
    };
    Vue.set(userlist, key, user);
}

//平滑滚动
function smoothScrollToBottom(chatBox) {
    const startScrollTop = chatBox.scrollTop;
    const targetScrollTop = chatBox.scrollHeight;
    const duration = 300; // 设置滚动时间（毫秒）
    let startTime;

    function scrollStep(timestamp) {
        if (!startTime) {
            startTime = timestamp;
        }

        const progress = timestamp - startTime;
        const easeInOutQuad = progress => progress < 0.5 ? 2 * progress * progress : -1 + (4 - 2 * progress) * progress;
        const scrollTop = startScrollTop + (targetScrollTop - startScrollTop) * easeInOutQuad(progress / duration);

        chatBox.scrollTop = scrollTop;

        if (progress < duration) {
            requestAnimationFrame(scrollStep);
        }
    }

    requestAnimationFrame(scrollStep);
}

//获取当前时间
function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    // 格式化小时和分钟，确保它们都是两位数
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    // 构建时间字符串（hh:mm 格式）
    const currentTime = `${formattedHours}:${formattedMinutes}`;
    return currentTime;
}

//初始化消息
function initSendMsg(event, type, msg, ava, oldname) {
    sendMsg.event = event
    sendMsg.type = type
    sendMsg.oldname = oldname
    sendMsg.time = getCurrentTime()
    sendMsg.name = userName
    sendMsg.msg = msg
    sendMsg.ava = ava
    return JSON.stringify(sendMsg)
}

//加密
function encryptMsg(msg, sessionKey) {
    var key = CryptoJS.enc.Utf8.parse(sessionKey);

    var encryptedData = CryptoJS.AES.encrypt(msg, key, {
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    encryptedData = encryptedData.ciphertext.toString();
    return encryptedData
}

//解密
function decryptMsg(msg, sessionKey) {
    var key = CryptoJS.enc.Utf8.parse(sessionKey);
    var encryptedHexStr = CryptoJS.enc.Hex.parse(msg);
    var encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

    var decryptedData = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    var decryptedStr = decryptedData.toString(CryptoJS.enc.Utf8);
    return decryptedStr

}

//发送消息到服务器
function sendToServer(event, type, msg, ava) {
    userName = localStorage.getItem("userName")
    var message = initSendMsg(event, type, msg, ava)
    //加密
    // message = encryptMsg(message, sessionKey)
    // console.log("encrypted msg\n\n"+message)
    console.log(message)
    ws.send(message)
}

//建立WebSocket连接
function connectWs() {
    // 连接成功时触发
    ws.onopen = () => {
        //通知连接成功
        if (userName != "" || userName != null) {
            if (userName != "default") {
                var msg = initSendMsg("userNum", "", "", avatar)
                ws.send(msg)
            }
        }
        addNewMessage("", "WebSocket Connected", "", false, botAvatar)
    }
    // 接收到消息时触发  
    ws.onmessage = (e) => {
        ////解密
        // const data = JSON.parse(decryptMsg(e.data,sessionKey))

        //将服务器广播的JSON格式化成data对象
        const data = JSON.parse(e.data, sessionKey)
        console.log(data)
        switch (data.event) {
            case 'message':
                if (userName != data.userName) {
                    if (data.type == "text") {
                        addNewMessage(data.userName, data.message, "", false, data.ava)
                    }
                    if (data.type == "img") {
                        addNewMessage(data.userName, "", data.message, false, data.ava)
                    }
                }
                break;

            case 'hismessage':
                if (data.message != undefined) {
                    if (userName != data.userName) {
                        //如果是别人发送的消息
                        if (data.type == "text") {
                            addNewMessage(data.userName, data.message, "", false, data.ava)
                        }
                        if (data.type == "img") {
                            addNewMessage(data.userName, "", data.message, false, data.ava)
                        }
                    } else {
                        //如果是自己发送的消息
                        if (data.type == "text") {
                            addNewMessage(data.userName, data.message, "", true, data.ava)
                        }
                        if (data.type == "img") {
                            addNewMessage(data.userName, "", data.message, true, data.ava)
                        }
                    }
                }
                break;

            //如果消息类型为用户加入
            case 'connected':

                break;

            //更新用户列表
            case 'updateUserList':

                clearUserList()

                addNewUser("Public", "https://n.sinaimg.cn/sinakd20114/534/w712h622/20201126/e9ae-kentcvx5398612.jpg")

                for (const key in data.userList) {
                    var name = data.userList[key].name
                    var avatar = data.userList[key].avatar
                    addNewUser(name, avatar)

                }

                EventBus.$emit('refreshUserList');

                break;
            case 'login':


                break;
            case 'trash':
                encryptMsg("", "")
                decryptMsg("", "")
                break;
            case 'sessionKey':
                sessionKey = data.message
                console.log(sessionKey)
                //通知连接成功
                if (userName != "" || userName != null) {
                    var msg = initSendMsg("userNum", "", "", "https://faixin.cn/images/SoP.ico")
                    var cipher = encryptMsg(msg, sessionKey)
                    ws.send(cipher)
                }
                break
            default:
                break;
        }

    };
    // 连接关闭时触发  
    ws.onclose = () => {
        addNewMessage("", "WebSocket Disconnected", "", false, botAvatar)

    };
}

//登录
function login(userName, pwd) {
    userName = localStorage.getItem("userName")
    if (pwd == "1816123123") {
        return
    }
}

const ws = new WebSocket("wss://chat.faixin.cn:8888/ws");
// const ws = new WebSocket("ws://localhost:8888/ws");


connectWs()

export default {
    userNames,
    userName,
    chatMsgs,
    sendMsg,
    avatar,
    privateMsgs,
    tempMsgs,
    msgPool,
    userlist,
    sessionKey,
    install: function (Vue) {
        Vue.prototype.addNewMessage = (name, message, img, flag, ava) => addNewMessage(name, message, img, flag, ava)
        Vue.prototype.sendToServer = (event, type, msg, ava) => sendToServer(event, type, msg, ava)
        Vue.prototype.login = (userName, pwd) => login(userName, pwd)
        Vue.prototype.jumpToWhere = () => jumpToWhere()
    },

}

